<template>

  <main class="contact" v-if="user">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
            Off Radio Kraków
          </router-link>

          <router-link to="/off-ludzie" title="Off Ludzie" class="breadcrumb">
            Off Ludzie
          </router-link>

          <router-link :to="user.url_full" :title="user.firstname +' '+user.lastname" class="breadcrumb">
            {{ user.firstname }} {{ user.lastname }}
          </router-link>
        </div>
      </div>


    </div>

    <div class="container">
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Off Ludzie</h1>
        </div>
      </div>

      <div class="people">

        <div class="row">
          <div class="col s12 l5 xl3">

            <div class="people__thumb"
                 :style="{ backgroundImage: `url(${user.avatar_url})` }">
            </div>
          </div>

          <div class="col s12 l7 xl9">

            <div class="people__info">
              <h1 class="people__title">
                {{ user.firstname }}
                {{ user.lastname }}
              </h1>
              <p class="people__text">
                {{ user.text }}
              </p>

              <ul class="people__list">
                <li v-if="user.position">
                  <b>Stanowisko:</b>
                  {{ user.position }}
                </li>
                <li v-if="user.phone">
                  <b>Telefon:</b>
                  {{ user.phone }}
                </li>
                <li v-if="user.mail">
                  <b>Mail:</b>
                  {{ user.mail }}
                </li>
              </ul>

              <ul class="list-social">
                <li class="list-social__item" v-if="user.link_website">
                  <a :href="user.link_website" target="_blank">
                    <i class="fas fa-globe"></i>
                  </a>
                </li>
                <li class="list-social__item" v-if="user.link_twitter">
                  <a :href="user.link_twitter" target="_blank">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li class="list-social__item" v-if="user.link_facebook">
                  <a :href="user.link_facebook" target="_blank">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li class="list-social__item" v-if="user.link_instagram">
                  <a :href="user.link_instagram" target="_blank">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

    </div>


    <FamilyUserList :id_user="user.id"/>
    <ArticleUserList :id_user="user.id"/>

    <br><br>

  </main>

</template>

<script>
import axios from "@/axios";
import ArticleUserList from "@/views/article/ArticleUserList";
import FamilyUserList from "@/views/article/FamilyUserList";
import {Carousel, Slide, Navigation} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default {
  name: "UserShow",
  components: {FamilyUserList, ArticleUserList},
  props: ['slug'],
  data() {
    return {
      user: null,
      loaded: false,
    }
  },
  created() {
    this.load();
  },
  methods: {
    load: function () {

      axios
          .get('user/find', {
            params: {
              url: this.$route.params.slug,
              scope: 'id,firstname,lastname,url_full,avatar_url,text,position,phone,mail,link_website,link_twitter,link_facebook,link_instagram'
            }
          })
          .then(response => {

            this.user = response.data.data;


            let domain = "https://off.radiokrakow.pl/api/"
            let domain_new = "https://off.radiokrakow.pl/"

            this.user.avatar_url = domain_new + this.user.avatar_url.substr(domain.length)
            this.loaded = true;

            let name = this.user.firstname+' '+this.user.lastname;

            this.$root.metaSet(name, this.user.text, this.user.avatar_url)

          });

    }
  },
  updated() {
  },
  watch: {

    '$route.params.slug': {
      handler: function (slug) {
        if (this.loaded) {
          this.load();
        }
        console.log(666)
      }
    }
  }
}
</script>

<style scoped>

</style>