<template>

  <section class="article" v-if="article && $route.params.slug == article.slug">

    <div class="article__thumb" :style="{ backgroundImage: `url(${article.thumb})` }">
      <div class="article__important" v-if="article.is_important">
        Ważne
      </div>
    </div>


    <div class="container">
      <div class="row">
        <div class="col s12 l8 offset-l2">

          <div class="article__info">
            <div class="row">
              <div class="col s12">
                <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
                  Off Radio Kraków
                </router-link>

                <router-link to="/newsy" title="Newsy" class="breadcrumb">
                  Newsy
                </router-link>
                <router-link :to="'/'+article.url" title="Newsy" class="breadcrumb">
                  {{ article.title }}
                </router-link>
              </div>
            </div>


            <ShareButton :url="article.manifest" :title="article.title" :text="article.slug" :thumb="article.thumb"
                         class="right"/>


            <h1 class="article__title big-title">{{ article.title }}</h1>


            <ul class="meta">

              <li class="meta__item" v-if="article.review == 'yes'">
                <div class="review-star">
                  <div class="review-star__bg"></div>
                  <div class="review-star__load"
                       :style="{ width: `${(article.review_star / 5)*100}%` }"></div>

                </div>
              </li>

              <li class="meta__item meta__item-fullopacity " v-if="article.user">

                <a :href="article.user.full_url"
                   class="user-item user-item-min">
                  <div class="user-item__thumb" :style="{ backgroundImage: `url(${article.user.avatar_url})` }"></div>
                  <div class="user-item__name">
                    {{ article.user.firstname }}
                    {{ article.user.lastname }}
                  </div>
                  <div class="user-item__position"></div>

                </a>
              </li>


              <li class="meta__item">
                <i class="material-icons">date_range</i>
                {{ article.date }}
              </li>
              <li class="meta__item meta__item-labels meta__item-fullopacity">

                <ul class="tag-list">
                  <li class="tag-list__item" v-for="label in article.labels">{{ label.name }}</li>
                </ul>
              </li>
            </ul>


            <video class="responsive-video" controls v-if="article.video_url">
              <source :src="article.video_url" type="video/mp4">
            </video>

            <p class="article__thumb-tags" v-if="article.thumb_tags">
              {{ article.thumb_tags }}
            </p>


            <article>

              <div class="article__text">{{ article.text }}</div>


              <div v-for="article_part in article.parts">
                <ArticleShowPart :article_part="article_part"/>
              </div>

              <br>
            </article>

            <div v-if="article.files">
              <br>
              <h3 class="min-title mb-3">
                Pliki
              </h3>

              <div class="file" v-for="file in article.files">
                <a class="file-download right color-text" :href="file.url_download">
                  <i class="material-icons">download</i>
                </a>
                <div class="file-name">
                  {{ file.name }}
                  <span class="grey-text ml-2">({{ file.size }})</span>
                </div>
                <div class="file-size"></div>
              </div>
            </div>


            <div v-if="article.location">
              <br>
              <h3 class="min-title mb-3">Adres</h3>
              <iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                      :src="article.location_ifram_url" class="radius"></iframe>
              <ul class="meta-list pt-0">
                <li class="meta__item">
                  <a :href="article.location_google_url" target="_blank">
                    <i class="material-icons">place</i>
                    {{ article.location_name }}
                  </a>
                </li>
              </ul>
            </div>


            <div v-if="article.review == 'yes'">
              <div class="row">
                <div class="col s12 l6">
                  <div v-if="article.review_plus_arr">
                    <br>
                    <h3 class="min-title mb-3">
                      Plusy
                    </h3>
                    <ul class="review-list review-list-plus">
                      <li class="review-list__item" v-for="review_plus in article.review_plus_arr">{{ review_plus }}
                      </li>
                    </ul>
                  </div>

                </div>
                <div class="col s12 l6">

                  <div v-if="article.review_minus_arr">
                    <br>
                    <h3 class="min-title mb-3">
                      Minusy
                    </h3>
                    <ul class="review-list review-list-plus">
                      <li class="review-list__item" v-for="review_minus in article.review_minus_arr">
                        {{ review_minus }}
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>


            <div class="article__foot" v-if="article.tags">

              <b>Tagi:</b>

              <ul class="tag-list">

                <li class="tag-list__item" v-for="label in article.labels">{{ label }}</li>

                <li class="tag-list__item" v-for="tag in article.tags">
                  <router-link :to="'/szukaj/'+tag" tag="a">
                    {{ tag }}
                  </router-link>
                </li>
              </ul>


              <div class="article__users" v-if="article.users.length > 0">
                <b>Autor:</b>
                <UserSmallList :users="article.users"/>
              </div>
              <div class="article__source" v-if="article.sources && article.sources.length > 0">
                <b>Źródło:</b>
                <ul>
                  <li v-for="source in article.sources">
                    <u v-if="source.type == 'none' || (source.type == 'text' && source.source == '') ">
                      Off Radio Kraków
                    </u>
                    <u v-if="source.type == 'text' ">{{ source.name }}</u>

                    <div class="user-item user-item-min" v-if="source.type == 'user'"
                         style="display: inline-block;">
                      <div class="user-item__thumb"
                           :style="{ backgroundImage: `url(${source.obj.avatar_url})` }"></div>
                      <div class="user-item__name">
                        {{ source.obj.firstname }}
                        {{ source.obj.lastname }}
                      </div>
                    </div>

                  </li>
                </ul>
              </div>

            </div>
          </div>

          <br><br>

          <div class="article__friend" v-if="article.friends && article.friends.length > 0">
            <div class="title-line mb-3">
              <h2>Zobacz także</h2>
            </div>
            <div class="row">
              <div class="col s12 l4" v-for="article in article.friends">
                <ArticleBlock :article="article"/>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <br><br><br><br><br><br><br>

  </section>
</template>

<script>
import axios from "@/axios";
import ArticleBlock from "@/views/article/ArticleBlock";
import ArticleShowPart from "@/views/article/ShowPart";
import ShareButton from "@/components/ShareButton";
import UserSmallList from "../user/SmallList";

export default {
  name: "ArticleShow",
  components: {
    UserSmallList,
    ShareButton,
    ArticleBlock,
    ArticleShowPart
  },
  props: ['slug'],
  data() {
    return {
      'article': null
    }
  },
  methods: {
    refresh: function () {


      axios
          .get('article/get', {
            params: {
              slug: this.slug,
              scope: 'title,block_thumb,thumb,url,date,slug,labels,video_url,review,review_star,text,parts,lead,users,tags,sources,manifest',
              parts_scope: 'type,thumb_url,thumb,thumb_tags,video_url,top_thumb_url,top_name,audio_url,top_thumb_url,top_url,id_top,text',
              users_scope: 'name,firstname,lastname,avatar_url,url_full',
              is_show: true
            }
          })
          .then(response => {

            let data = response.data;

            let domain = "https://off.radiokrakow.pl/api/"
            let domain_new = "https://off.radiokrakow.pl/"

            $.each(data.data, function (key, val) {


              val.thumb = domain_new + val.thumb.substr(domain.length)

              if (val.tags && !Array.isArray(val.tags)) {
                val.tags = val.tags.split(',');

              }


              $.each(data.data[0].parts, function (part_key, part_val) {

                if (part_val.thumb) {


                  part_val.thumb = domain_new + part_val.thumb.substr(domain.length)
                }

              });


              $.each(data.data[0].sources, function (source_key, source_val) {

                if (source_val.obj && source_val.obj.avatar_url) {


                  source_val.obj.avatar_url = domain_new + source_val.obj.avatar_url.substr(domain.length)
                }

              });

            });

            this.article = data.data[0];

            this.$root.metaSet(this.article.title, this.article.lead, this.article.thumb)

          })

    }
  },
  created() {

    this.refresh();

  }

}
</script>

<style scoped>

</style>