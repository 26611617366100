<template>


  <div class="family-show" v-if="family">

    <div class="family-show__thumb"
         :style="{ backgroundImage: `url(${family.thumb})` }">

    </div>
    <div class="container">
      <div class="row">

        <div class="col s12 xl8 offset-xl2">

          <div class="family-show__info">

            <div class="row">
              <div class="col s12">
                <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
                  Off Radio Kraków
                </router-link>

                <router-link to="/audycje" title="Audycje" class="breadcrumb">
                  Audycje
                </router-link>
                <router-link :to="'/'+family.url" title="Newsy" class="breadcrumb">
                  {{ family.title }}
                </router-link>
              </div>
            </div>


            <h1 class="big-title">
              {{ family.title }}
            </h1>


            <div class="family-show__roadmap" v-if="family.status == 'live'">
              {{ family.roadmap_str }}
            </div>

            <div class="family-show__archive" style="display: none;" v-if="family.status == 'archive'">
              Audycja archiwalna
            </div>


            <p class="family-show__text mb-4" v-html="family.text"></p>


            <UserSmallList :users="family.users"/>
          </div>

        </div>
      </div>
    </div>

  </div>

  <div class="container">
    <div class="row">
      <div class="col s12 xl8 offset-xl2">


        <div class="title-line mb-3 mt-3">

          <h2>Podcasty</h2>

        </div>


        <div v-if="!articles || articles.length ==0 ">


          <div class="clearfix"></div>
          <div class="card white darken-1">
            <div class="card-content black-text">
              <p>Brak</p>
            </div>
          </div>

        </div>

        <div v-if="articles">
          <PodcastLine :article="article" v-for="article in articles"/>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import axios from "@/axios";
import UserSmallList from "@/views/user/SmallList";
import PodcastLine from "@/views/article/PodcastLine";

export default {
  name: "ArticleFamilyShow",
  components: {PodcastLine, UserSmallList},
  props: ['slug'],
  data() {
    return {
      'family': null,
      'articles': null,
    }
  },
  methods: {
    refresh: function () {


      axios
          .get('articlefamily/get', {
            params: {
              slug: this.slug,
              scope: 'title,thumb,roadmap_str,slug,date,url,text,status,users,articles',
              articles_scope: 'url,title,block_thumb,text,users,audio,audio_url,audio_time,id,labels,date',
              users_scope: 'name,firstname,lastname,account_type,avatar_url,url_full',
              is_show: true
            }
          })
          .then(response => {

            let data = response.data;

            let domain = "https://off.radiokrakow.pl/api/"
            let domain_new = "https://off.radiokrakow.pl/"
            $.each(data.data, function (key, val) {


              val.thumb = domain_new + val.thumb.substr(domain.length);

            });

            $.each(data.data[0].articles, function (key, val) {

              if (val.thumb)
                val.thumb = domain_new + val.thumb.substr(domain.length);

              if (val.block_thumb)
                val.block_thumb = domain_new + val.block_thumb.substr(domain.length);

              if (val.audio_url)
                val.audio_url = domain_new + val.audio_url.substr(domain.length);
              val.date = val.date.substr(0, 10);
            });
            this.family = data.data[0];
            this.articles = data.data[0].articles;

            this.$root.metaSet(this.family.title, this.family.text, this.family.thumb)

          })

    }
  },
  created() {

    this.refresh();

  }
}
</script>

<style scoped>

</style>