<template>

  <main class="contact">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
            Off Radio Kraków
          </router-link>

          <router-link to="/off-ludzie" title="Off Ludzie" class="breadcrumb">
            Off Ludzie
          </router-link>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Off Ludzie</h1>
        </div>
      </div>
      <div class="row">
        <div class="col s12" v-for="user in users">
          <UserLine :user="user"/>
        </div>
      </div>

      <div class="row">
        <div class="col s12">

          <Pagination :page="page" :count="count"/>
        </div>
      </div>

    </div>

    <SectionInfo code="people"/>
  </main>
</template>

<script>
import UserLine from "@/views/user/Line";
import SectionInfo from "@/views/home/SectionInfo";
import axios from "@/axios";
import Pagination from "@/components/Pagination";

export default {
  name: "UserList",
  components: {Pagination, UserLine, SectionInfo},
  data() {
    return {
      'page': 1,
      'count': null,
      'users': null

    }
  },
  methods: {
    refresh: function () {

      axios
          .get('user/get', {
            params: {
              sort: 'lastname',
              page: this.page,
              scope: 'firstname,lastname,avatar_url,url_full'
            }
          })
          .then(response => {


            $.each(response.data.data, function (key, val) {
              let domain = "https://off.radiokrakow.pl/api/"
              let domain_new = "https://off.radiokrakow.pl/"

              val.avatar_url = domain_new + val.avatar_url.substr(domain.length)

            })

            // this.page? = response.data.data;
            this.users = response.data.data;
            this.count = response.data.count;

          })

    }
  },
  watch: {
    page: function (val) {
      this.refresh();
    },
  },
  created() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>